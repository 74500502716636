body {
  background: url("bg.d8855926.png");
  margin: 0;
  padding: 0;
  font: 14px Tahoma, Geneva, sans-serif;
  overflow-y: scroll;
}

.wrapper {
  width: 902px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

#logo {
  margin: 0 0 20px 42px;
  padding: 0;
}

#menu {
  width: 898px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0 0 10px #c6c6c6;
}

#menu img {
  position: absolute;
  left: 576px;
}

#header {
  height: 150px;
  margin: 0;
  padding: 0;
  position: relative;
}

#header_1 {
  height: 150px;
  margin: 0;
  padding: 0;
}

#header_2 {
  height: 150px;
  margin-left: 1px;
}

#header_3 {
  height: 150px;
  margin-left: 363px;
}

#header_3 img {
  margin-top: 10px;
}

#footer {
  width: 100%;
  border-top: 1px solid #c5c5c5;
  margin: 0 0 20px;
  padding: 0;
}

.clear {
  clear: both;
}

h2 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

h4 {
  font-size: 12px;
}

.slogan {
  position: relative;
  top: 15px;
}

.divider {
  width: 900px;
  border-bottom: 1px solid #bdbdbd;
  margin: 10px 0 20px -30px;
  padding: 0;
}

.divider_last {
  border: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #999;
  text-decoration: underline;
}

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu li {
  width: 140px;
  height: 48px;
  float: left;
  display: inline;
}

.menu li a {
  color: #3b3b3b;
  text-align: center;
  background: url("menu_line.2861b212.jpg") 139px 10px no-repeat;
  font-family: Open Sans, sans-serif;
  line-height: 48px;
  text-decoration: none;
  display: block;
}

.menu li a:hover, #active a {
  color: #fff;
  background: url("bg_menu.e7afd392.jpg") no-repeat;
}

.header_txt {
  width: 600px;
  text-align: center;
  margin: 0;
}

.not_prior {
  color: #444;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
}

#prior {
  color: #33b8bd;
  text-transform: uppercase;
  font-size: 30px;
  display: block;
}

.elevator {
  position: absolute;
  top: -65px;
  left: 650px;
}

#about_main {
  width: 599px;
  float: left;
  border-right: 1px solid #c5c5c5;
  margin: 0;
  padding: 0;
}

.header_about {
  height: 48px;
  background: url("bg_head_about.79395e3a.jpg") no-repeat;
  border: 1px solid #c5c5c5;
  border-right: none;
  margin-left: 1px;
}

.header_text {
  color: #33b8bd;
  letter-spacing: -1px;
  padding: 0 0 0 30px;
  font-size: 30px;
  line-height: 45px;
}

.container_about {
  text-align: justify;
  margin: 0;
  padding: 0 30px 10px;
}

.container_about img {
  margin: 30px 0 10px;
}

.container_about p {
  margin-bottom: 20px;
}

.services {
  margin-bottom: 0;
  margin-left: 23px;
  padding: 0;
}

.btn_read_more {
  display: inline;
}

.btn_read_more:hover {
  opacity: .8;
  filter: alpha(opacity= 80);
  -moz-opacity: .8;
}

.container_contact {
  margin: 30px 30px 30px 71px;
}

.fax_main {
  line-height: 25px;
  position: relative;
}

.container_news p {
  color: #888;
  padding: 10px 30px;
}

#header_1 img {
  margin: 15px 0 0 317px;
}

#about_company {
  width: 100%;
  margin: 0;
  padding: 0;
}

.header_company {
  height: 48px;
  width: 898px;
  background: url("bg_head_about_company.b82f1cc9.jpg") no-repeat;
  border: 1px solid #c5c5c5;
  margin-left: 1px;
}

.container_project {
  text-align: justify;
  margin: 0;
  padding: 0 30px;
}

.img_item {
  width: 144px;
  float: left;
  margin-right: 30px;
  padding: 0;
  display: block;
}

.prj_item img:hover {
  -o-transition: all .3s ease;
  transition: all .3s;
  box-shadow: 0 0 10px #555;
}

.vert {
  margin-left: 32px;
}

.services_1 {
  float: left;
  margin-bottom: 30px;
  margin-left: 23px;
  padding: 0;
}

.old_img {
  float: left;
}

.old_img_vert {
  float: left;
  margin-left: 32px;
}

.place {
  font-size: 12px;
  font-weight: 400;
}

.contact_txt {
  margin: 0 0 0 520px;
  padding-top: 15px;
}

.contact_txt span {
  font-weight: 800;
}

.map {
  float: left;
  margin: 0 0 30px;
  padding: 0;
}

.footer_nav ul {
  margin-left: 225px;
}

.footer_nav ul li {
  border-left: 1px solid #444;
  margin-right: 5px;
  display: inline;
}

.footer_nav ul li a {
  color: #444;
  margin-left: 11px;
  font-size: 14px;
  text-decoration: none;
}

.footer_nav ul li a:hover {
  color: #777;
  text-decoration: underline;
}

#footer p {
  text-align: center;
}

#main, .prj_item {
  margin: 0;
  padding: 0;
}

img, .footer_nav ul li:first-child {
  border: none;
}

#contact_main, #news_main {
  width: 299px;
  margin: 0 0 0 600px;
  padding: 0;
}

.header_contact, .header_news {
  height: 48px;
  background: url("bg_head_contact.ba25d1b2.jpg") no-repeat;
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
}

.services li, .services_1 li {
  text-indent: -20px;
  list-style: none;
}

.services li:before, .services_1 li:before {
  color: #33b8bd;
  content: "•";
  padding-right: 15px;
}

.btn_read_more, .last_img {
  margin: 0;
}

.email_main, .phone_main {
  line-height: 20px;
  position: relative;
}

.email_main img, .phone_main img, .fax_main img {
  position: absolute;
  top: -1px;
  left: -40px;
}

/*# sourceMappingURL=about.217879dc.css.map */
