body {
  background: url(../images/bg.png);
  font: 14px Tahoma, Geneva, sans-serif;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 902px;
  position: relative;
  margin: 0 auto;
  padding: 0;
}

#logo {
  margin: 0 0 20px 42px;
  padding: 0;
}

#menu {
  width: 898px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  -webkit-box-shadow: 0 0 10px #c6c6c6;
  -moz-box-shadow: 0 0 10px #c6c6c6;
  box-shadow: 0 0 10px #c6c6c6;
  margin: 0 auto;
  padding: 0;
}

#menu img {
  position: absolute;
  left: 576px;
}

#header {
  height: 150px;
  position: relative;
  margin: 0;
  padding: 0;
}

#header_1 {
  height: 150px;
  margin: 0;
  padding: 0;
}

#header_2 {
  height: 150px;
  margin-left: 1px;
}

#header_3 {
  height: 150px;
  margin-left: 363px;
}

#header_3 img {
  margin-top: 10px;
}

#footer {
  width: 100%;
  border-top: 1px solid #c5c5c5;
  margin: 0 0 20px;
  padding: 0;
}

.clear {
  clear: both;
}

h2 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
}

h4 {
  font-size: 12px;
}

.slogan {
  position: relative;
  top: 15px;
}

.divider {
  width: 900px;
  border-bottom: 1px solid #bdbdbd;
  margin: 10px 0 20px -30px;
  padding: 0;
}

.divider_last {
  border: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #999;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  width: 140px;
  height: 48px;
  float: left;
  display: inline;
}

.menu li a {
  display: block;
  text-decoration: none;
  color: #3b3b3b;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  line-height: 48px;
  background: url(../images/menu_line.jpg) no-repeat 139px 10px;
}

.menu li a:hover,
#active a {
  background: url(../images/bg_menu.jpg) no-repeat 0 0;
  color: #fff;
}

.header_txt {
  width: 600px;
  text-align: center;
  margin: 0;
}

.not_prior {
  font-size: 20px;
  color: #444;
  font-family: 'Open Sans', sans-serif;
}

#prior {
  font-size: 30px;
  color: #33b8bd;
  text-transform: uppercase;
  display: block;
}

.elevator {
  position: absolute;
  left: 650px;
  top: -65px;
}

#about_main {
  width: 599px;
  float: left;
  border-right: 1px solid #c5c5c5;
  margin: 0;
  padding: 0;
}

.header_about {
  height: 48px;
  border: 1px solid #c5c5c5;
  border-right: none;
  background: url(../images/bg_head_about.jpg) no-repeat 0 0;
  margin-left: 1px;
}

.header_text {
  font-size: 30px;
  color: #33b8bd;
  letter-spacing: -1px;
  line-height: 45px;
  padding: 0 0 0 30px;
}

.container_about {
  text-align: justify;
  margin: 0;
  padding: 0 30px 10px;
}

.container_about img {
  margin: 30px 0 10px;
}

.container_about p {
  margin-bottom: 20px;
}

.services {
  margin-bottom: 0;
  margin-left: 23px;
  padding: 0;
}

.btn_read_more {
  display: inline;
}

.btn_read_more:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
}

.container_contact {
  margin: 30px 30px 30px 71px;
}

.fax_main {
  position: relative;
  line-height: 25px;
}

.container_news p {
  color: #888;
  padding: 10px 30px;
}

#header_1 img {
  margin: 15px 0 0 317px;
}

#about_company {
  width: 100%;
  margin: 0;
  padding: 0;
}

.header_company {
  background: url(../images/bg_head_about_company.jpg) no-repeat 0 0;
  border: 1px solid #c5c5c5;
  height: 48px;
  width: 898px;
  margin-left: 1px;
}

.container_project {
  text-align: justify;
  margin: 0;
  padding: 0 30px;
}

.img_item {
  width: 144px;
  display: block;
  float: left;
  margin-right: 30px;
  padding: 0;
}

.prj_item img:hover {
  -webkit-box-shadow: 0 0 10px #555;
  -moz-box-shadow: 0 0 10px #555;
  box-shadow: 0 0 10px #555;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.vert {
  margin-left: 32px;
}

.services_1 {
  margin-bottom: 30px;
  margin-left: 23px;
  float: left;
  padding: 0;
}

.old_img {
  float: left;
}

.old_img_vert {
  float: left;
  margin-left: 32px;
}

.place {
  font-size: 12px;
  font-weight: 400;
}

.contact_txt {
  padding-top: 15px;
  margin: 0 0 0 520px;
}

.contact_txt span {
  font-weight: 800;
}

.map {
  float: left;
  margin: 0 0 30px;
  padding: 0;
}

.footer_nav ul {
  margin-left: 225px;
}

.footer_nav ul li {
  display: inline;
  border-left: 1px solid #444;
  margin-right: 5px;
}

.footer_nav ul li a {
  color: #444;
  text-decoration: none;
  margin-left: 11px;
  font-size: 14px;
}

.footer_nav ul li a:hover {
  color: #777;
  text-decoration: underline;
}

#footer p {
  text-align: center;
}

#main,
.prj_item {
  margin: 0;
  padding: 0;
}

img,
.footer_nav ul li:first-child {
  border: none;
}

#contact_main,
#news_main {
  width: 299px;
  margin: 0 0 0 600px;
  padding: 0;
}

.header_contact,
.header_news {
  height: 48px;
  border-top: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  background: url(../images/bg_head_contact.jpg) no-repeat 0 0;
}

.services li,
.services_1 li {
  list-style: none;
  text-indent: -20px;
}

.services li:before,
.services_1 li:before {
  color: #33b8bd;
  content: "\2022";
  padding-right: 15px;
}

.btn_read_more,
.last_img {
  margin: 0;
}

.email_main,
.phone_main {
  position: relative;
  line-height: 20px;
}

.email_main img,
.phone_main img,
.fax_main img {
  position: absolute;
  top: -1px;
  left: -40px;
}
